
import Component from '../../../assets/scripts/modules/component'

const MIN_WIDTH = 800

class StoryImageHeaderComponent extends Component {
  init () {
    this.currentSelectedIndex = 0
    this.initSelectors()
    this.initProgressBars()
    this.onKeyPress()
    this.initClick()
    window.addEventListener('resize', this.onResize.bind(this))
  }

  initSelectors () {
    // card wrapper can have one or two cards. It receives an array
    this.cardsWrapper = Array.from(this.element.querySelectorAll('[data-role="cards-list"]'))
    this.cards = Array.from(this.element.querySelectorAll('[data-role="card"]'))
    this.progressBarWrapper = this.element.querySelector('[data-role="progress-bar-wrapper"]')
    this.clickableContainer = this.element.querySelector('.story-image-list__clickable-container')
  }

  initProgressBars () {
    // two different progress bars containers for mobile and desktop
    const progressBarContainerMobile = document.createElement('div')
    progressBarContainerMobile.classList.add('progress-bar-container')
    progressBarContainerMobile.dataset.role = 'progress-bar-container-mobile'

    const progressBarContainerDesktop = document.createElement('div')
    progressBarContainerDesktop.classList.add('progress-bar-container')
    progressBarContainerDesktop.dataset.role = 'progress-bar-container-desktop'

    this.progressBarWrapper.appendChild(progressBarContainerMobile)
    this.progressBarWrapper.appendChild(progressBarContainerDesktop)

    this.cards.forEach(() => { this.createProgressBars(progressBarContainerMobile) })
    this.cardsWrapper.forEach(() => { this.createProgressBars(progressBarContainerDesktop) })

    this.progressBarsMobile = Array.from(progressBarContainerMobile.childNodes)
    this.progressBarsDesktop = Array.from(progressBarContainerDesktop.childNodes)

    this.progressBarsMobile.forEach((el, index) => {
      el.addEventListener('click', () => { this.onClickBar(index) })
    })

    this.progressBarsDesktop.forEach((el, index) => {
      el.addEventListener('click', () => { this.onClickBar(index) })
    })

    if (window.innerWidth < MIN_WIDTH) {
      this.progressBar = this.progressBarsMobile
      this.cards[this.currentSelectedIndex].classList.add('card-image-story--visible')
      this.cardsWrapper.forEach((cardWrapper) => { cardWrapper.classList.add('story-image-list__container--visible') })
    } else {
      this.progressBar = this.progressBarsDesktop
      this.initCardsWrapper()
      this.cards.forEach((card) => { card.classList.add('card-image-story--visible') })
    }

    this.progressBar[this.currentSelectedIndex].classList.add('progress-bar--selected')
  }

  createProgressBars (el) {
    const progressBar = document.createElement('div')
    if (el.dataset.role === 'progress-bar-container-desktop') {
      progressBar.classList.add('progress-bar-desktop', 'story-image-list__progress-bar')
    } else {
      progressBar.classList.add('progress-bar-mobile', 'story-image-list__progress-bar')
    }
    progressBar.dataset.role = 'progress-bar'
    el.appendChild(progressBar)
  }

  initCardsWrapper () {
    this.cardsWrapper.forEach((cardWrapper, index) => {
      if (index === this.currentSelectedIndex) {
        cardWrapper.classList.add('story-image-list__container--visible')
      } else {
        cardWrapper.classList.remove('story-image-list__container--visible')
      }
    })
  }

  onKeyPress () {
    document.onkeydown = (e) => {
      if (e.key === 'ArrowRight') {
        this.setNextIndex()
      } else if (e.key === 'ArrowLeft') {
        this.setPreviousIndex()
      }
    }
  }

  initClick () {
    const setIndex = (e) => {
      // click on the right side of screen
      if (e.clientX >= (window.innerWidth / 2)) {
        this.setNextIndex()
      } else {
        this.setPreviousIndex()
      }
    }

    this.clickableContainer.addEventListener('click', (e) => { setIndex(e) })
  }

  setNextIndex () {
    if (this.currentSelectedIndex < (window.innerWidth < MIN_WIDTH ? this.cards.length : this.cardsWrapper.length) - 1) {
      this.currentSelectedIndex += 1
      this.setCardsToShow()
    }
  }

  setPreviousIndex () {
    if (this.currentSelectedIndex > 0) {
      this.currentSelectedIndex -= 1
      this.setCardsToShow()
    }
  }

  onResize () {
    const selectedProgressBar = this.element.querySelector('.progress-bar--selected')

    if (window.innerWidth < MIN_WIDTH) {
      if (!selectedProgressBar.classList.contains('progress-bar-mobile')) {
        selectedProgressBar.classList.remove('progress-bar--selected')
        this.cards.forEach((card) => card.classList.remove('card-image-story--visible'))
        this.cardsWrapper[this.currentSelectedIndex].firstElementChild.classList.add('card-image-story--visible')
        this.progressBar = this.progressBarsMobile
        this.cardsWrapper.forEach((cardWrapper) => { cardWrapper.classList.add('story-image-list__container--visible') })
        this.currentSelectedIndex = this.cards.findIndex((card) => card.classList.contains('card-image-story--visible'))
        this.setCardsToShow()
      }
    } else {
      if (!selectedProgressBar.classList.contains('progress-bar-desktop')) {
        selectedProgressBar.classList.remove('progress-bar--selected')
        const currentCard = this.element.querySelector('.card-image-story--visible')
        this.cardsWrapper.forEach((cardWrapper, index) => {
          if (cardWrapper.contains(currentCard)) {
            cardWrapper.classList.add('story-image-list__container--visible')
            this.currentSelectedIndex = index
          } else {
            cardWrapper.classList.remove('story-image-list__container--visible')
          }
        })
        this.progressBar = this.progressBarsDesktop
        this.cards.forEach((card) => { card.classList.add('card-image-story--visible') })
        this.setCardsToShow()
      }
    }
  }

  onClickBar (index) {
    if (this.currentSelectedIndex !== index) {
      this.currentSelectedIndex = index
      this.setCardsToShow()
    }
  }

  setCardsToShow () {
    this.progressBar.forEach((bar, index) => {
      if (index === this.currentSelectedIndex) {
        bar.classList.add('progress-bar--selected')
      } else {
        bar.classList.remove('progress-bar--selected')
      }
    })

    if (window.innerWidth < MIN_WIDTH) {
      this.cards.forEach((card, index) => {
        if (index === this.currentSelectedIndex) {
          card.classList.add('card-image-story--visible')
        } else {
          card.classList.remove('card-image-story--visible')
        }
      })
    } else {
      this.cardsWrapper.forEach((cardWrapper, index) => {
        if (index === this.currentSelectedIndex) {
          cardWrapper.classList.add('story-image-list__container--visible')
        } else {
          cardWrapper.classList.remove('story-image-list__container--visible')
        }
      })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.story-image-list').forEach(element => {
  element.instance = element.instance || new StoryImageHeaderComponent(element)
}))
