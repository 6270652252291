import { apiCall, POST } from '../../../../assets/scripts/utilities/api'
import { NewsletterBackend, Response, STATUS_ERROR, STATUS_OK } from './base'

class RealNewsletterBackend extends NewsletterBackend {
  constructor (config) {
    super()
    this.fetchUrl = config.url
  }

  async subscribe (emailAddress) {
    let response

    try {
      response = await apiCall(POST, this.fetchUrl, { emailAddress: emailAddress }, window.TBM_APP_DATA.csrfToken)
    } catch (error) {
      console.log(error.message)
      return new Response(STATUS_ERROR, 'Something went wrong.')
    }
    const data = {
      status: response.status === 'ok' ? STATUS_OK : STATUS_ERROR,
      message: response.message,
      redirectURL: response.redirect
    }
    return new Response(data.status, data.message, data.redirectURL)
  }
}

export { RealNewsletterBackend }
