
export default class Component {
  constructor (element) {
    this.element = element

    // console.log(Object.getPrototypeOf(this)?.constructor?.name, 'loaded')

    this.init()

    // We return this object to bind the instance.
    return this
  }

  static init () {
    // Override this method
  }
}
