import Openseadragon from 'openseadragon'

import Component from '../../../assets/scripts/modules/component'

class ImageHeaderComponent extends Component {
  init () {
    const dzi = this.element.dataset.dzi
    if (dzi) {
      const element = this.element.querySelector('.image-header__osd')
      Openseadragon({
        element: element,
        prefixUrl: '/media/deepzoom/',
        tileSources: `/media/deepzoom/${dzi}`,
        zoomPerScroll: 1,
        toolbar: 'viewerToolbar',
        zoomInButton: 'zoom-in',
        zoomOutButton: 'zoom-out',
        fullPageButton: 'full-page'
      })
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.image-header').forEach(element => {
  element.instance = element.instance || new ImageHeaderComponent(element)
}))
