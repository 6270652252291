import Component from '../../../assets/scripts/modules/component'

class WormHole extends Component {
  init () {
    this.initSelectors()
    this.initBehavior()
  }

  initSelectors () {
    this.container = document.querySelector('.wormhole__wrapper')
    this.layer1 = document.querySelector('.wormhole__layer-1')
    this.layer2 = document.querySelector('.wormhole__layer-2')
  }

  initBehavior () {
    let state = 1
    this.container.addEventListener('mousemove', e => {
      this.layer1.style.setProperty(`--clip-position-1 ${e.offsetX}px ${e.offsetY}px`)
      this.layer2.style.setProperty(`--clip-position-2 ${e.offsetX}px ${e.offsetY}px`)
    })

    this.container.addEventListener('click', e => {
      if (!this.container.classList.contains('animating')) {
        this.container.classList.toggle('animating')
        if (state === 1) {
          state = 2
          this.layer1.classList.toggle('wormhole__startgrow')
          this.layer1.classList.toggle('wormhole__endgrow')
          this.layer2.classList.toggle('wormhole__startgrow')
          setTimeout(() => {
            this.layer1.classList.toggle('wormhole__front')
            this.layer2.classList.toggle('wormhole__endgrow')
          }, 300)
          setTimeout(() => {
            this.container.classList.toggle('animating')
          }, 1000)
        } else {
          state = 1
          this.layer2.classList.toggle('wormhole__startgrow')
          this.layer2.classList.toggle('wormhole__endgrow')
          this.layer1.classList.toggle('wormhole__startgrow')
          setTimeout(() => {
            this.layer1.classList.toggle('wormhole__endgrow')
            this.layer1.classList.toggle('wormhole__front')
          }, 300)
          setTimeout(() => {
            this.container.classList.toggle('animating')
          }, 1000)
        }
      }
    })
  }
}
window.addEventListener('init-load', () => document.querySelectorAll('.wormhole').forEach(element => {
  element.instance = element.instance || new WormHole(element)
}))
