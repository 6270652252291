import Component from '../../../assets/scripts/modules/component'

class FadeTrail extends Component {
  init () {
    this.initSelectors()
    this.initBehavior()
  }

  initSelectors () {
    this.container = document.querySelector('.fadetrail__wrapper')
    this.layer3 = document.querySelector('.fadetrail__layer-3')
    this.cursor = document.querySelector('.fadetrail__cursor')
    this.cursorX = 0
    this.cursorY = 0
    this.prevCursorX = 0
    this.prevCursorY = 0
  }

  initBehavior () {
    function repeating () {
      if (!(this.cursorX === this.prevCursorX && this.cursorY === this.prevCursorY) && !window.scrollY) {
        this.original = document.getElementById('fadetrail__cursor')
        const clone = this.original.cloneNode(true) // "deep" clone
        clone.id = 'duplicated' + ++i // there can only be one element with an cursor ID
        this.original.parentNode.appendChild(clone)
        currentTrails.add(clone.id)
        this.prevCursorX = this.cursorX
        this.prevCursorY = this.cursorY

        setTimeout(function () {
          if (currentTrails.has(clone.id)) {
            currentTrails.delete(clone.id)
            clone.parentNode.removeChild(clone)
          }
        }, 2000)
      }
      setTimeout(repeating.bind(this), 200)
    }

    let state = 1
    let i = 0
    const currentTrails = new Set()

    window.addEventListener('scroll', () => {
      if (!currentTrails.size) {
        return
      }

      Array.from(currentTrails).forEach(trailId => {
        currentTrails.delete(trailId)
        const elem = document.getElementById(trailId)
        elem.parentNode.removeChild(elem)
      })
    })

    this.container.addEventListener('mousemove', e => {
      this.cursor.style.left = this.cursorX = e.clientX + 'px'
      this.cursor.style.top = this.cursorY = e.clientY + 'px'
      this.cursorX = e.clientX
      this.cursorY = e.clientY
    })

    this.container.addEventListener('click', e => {
      if (!this.container.classList.contains('animating')) {
        this.container.classList.toggle('animating')
        if (state === 1) {
          state = 2
          this.layer3.classList.toggle('fadetrail__visible')
          setTimeout(() => {
            this.container.classList.toggle('animating')
          }, 1000)
        } else {
          state = 1
          this.layer3.classList.toggle('fadetrail__visible')
          setTimeout(() => {
            this.container.classList.toggle('animating')
          }, 1000)
        }
      }
    })
    repeating.bind(this)()
  }
}
window.addEventListener('init-load', () => document.querySelectorAll('.fadetrail').forEach(element => {
  element.instance = element.instance || new FadeTrail(element)
}))
