import Component from '../../../assets/scripts/modules/component'
import fireCustomEvent from '../../../assets/scripts/utilities/fire-custom-event'

export default class MenuBarComponent extends Component {
  init () {
    this.buttonMenu = this.element.querySelector('.icon-button--menu')
    this.isTransparent = false
    this.isWhite = this.element.classList.contains('menu-bar--white-text')

    if (this.buttonMenu) {
      this.buttonMenu.addEventListener('click', () => {
        this.buttonMenu.classList.toggle('opened')
        fireCustomEvent('open-menu-overlay')
      }
      )
    }

    window.addEventListener('close-menu-overlay', () => {
      if (this.buttonMenu) this.buttonMenu.classList.toggle('opened')
    })

    window.addEventListener('hide-menu-bar', () => {
      this.element.classList.add('menu-bar--hidden')
    })

    window.addEventListener('show-menu-bar', e => {
      this.element.classList.remove('menu-bar--hidden')
    })
  }

  changeBackgroundColor () {
    if (this.element.classList.contains('menu-bar--transparent') &&
    !this.element.classList.contains('menu-bar--no-change-on-scrolling')
    ) {
      this.isTransparent = true
    }

    if (this.isTransparent) {
      if (window.pageYOffset > 20) {
        this.element.classList.remove('menu-bar--transparent')
        this.element.classList.add('menu-bar--default')
        if (this.isWhite) {
          this.element.classList.remove('menu-bar--white-text')
          this.element.classList.add('menu-bar--black-text')
        }
      } else {
        this.element.classList.add('menu-bar--transparent')
        this.element.classList.remove('menu-bar--default')
        if (this.isWhite) {
          this.element.classList.add('menu-bar--white-text')
          this.element.classList.remove('menu-bar--black-text')
        }
      }
    }
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.menu-bar').forEach(element => {
  element.instance = element.instance || new MenuBarComponent(element)
}))
