
// import konamiCode from './utilities/konami-code.js'

// Import in reverse order from stylesheets
require('../../components/structures/footer-bar/footer-bar.js')
require('../../components/structures/footer-bar/newsletter/base.js')
require('../../components/structures/footer-bar/newsletter/dummy.js')
require('../../components/structures/footer-bar/newsletter/real.js')
require('../../components/structures/menu-bar/menu-bar.js')
require('../../components/structures/menu-overlay/menu-overlay.js')
require('../../components/structures/skip-to-main/skip-to-main.js')
require('../../components/organisms/article-photo-carousel/article-photo-carousel.js')
require('../../components/organisms/card-carousel/card-carousel.js')
require('../../components/organisms/card-grid-audience/card-grid-audience.js')
require('../../components/organisms/card-grid-home/card-grid-home.js')
require('../../components/organisms/card-mosaic/card-mosaic.js')
require('../../components/organisms/event-header/event-header.js')
require('../../components/organisms/event-photo-carousel/event-photo-carousel.js')
require('../../components/organisms/filter-bar/filter-bar.js')
require('../../components/organisms/homepage-header/homepage-header.js')
require('../../components/organisms/image-header/image-header.js')
require('../../components/organisms/story-image-header/story-image-header.js')
require('../../components/organisms/story-image-list/story-image-list.js')
require('../../components/organisms/story-panorama/story-panorama.js')
require('../../components/organisms/story-video-embed/story-video-embed.js')
require('../../components/molecules/fadetrail/fadetrail.js')
require('../../components/molecules/form/form.js')
require('../../components/molecules/notification-bar/notification-bar.js')
require('../../components/molecules/wormhole/wormhole.js')
require('../../components/atoms/audio-fragment/audio-fragment.js')
require('../../components/atoms/datepicker/datepicker.js')
require('../../components/atoms/disclosure/disclosure.js')
require('../../components/atoms/picture/picture.js')
require('../../components/atoms/scroll-down-button/scroll-down-button.js')
require('../../components/atoms/share-button/share-button.js')
require('../../components/atoms/video-embed/video-embed.js')
require('../../components/atoms/video/video.js')
const { apiCall, GET } = require('./utilities/api')

console.log('\n %cMade with %c♥%c by Fabrique \n', 'font: 16px serif;', 'font: 13px serif; color: #f00;', 'font: 16px serif;')

// There are 4 load events:
// - 'init-immediate': (compiles when the script is loaded; blocks rendering)
// - 'init-load': (on DOMContentLoaded event; does not block rendering)
// - 'init-after-load': (on Load event, slightly after DOMContentLoaded)
// - 'init-delayed-load': (after Load event, with a slight delay, for iframes and such)
// Usually, the 'init-load' event will suffice.
window.dispatchEvent(new CustomEvent('init-immediate'))
window.addEventListener('DOMContentLoaded', () => window.dispatchEvent(new CustomEvent('init-load')))
window.addEventListener('load', () => window.dispatchEvent(new CustomEvent('init-after-load')))
window.addEventListener('load', () => window.setTimeout(() => window.dispatchEvent(new CustomEvent('init-delayed-load')), 1000))

// Focus stuff
document.addEventListener('keydown', () => document.documentElement.classList.add('key-pressed'))
document.addEventListener('mousedown', () => document.documentElement.classList.remove('key-pressed'))
document.addEventListener('touchstart', () => document.documentElement.classList.remove('key-pressed'))

// This gives you vh-like values, but without scrollbars and addressbar and such
// This allows usage of document height / width in CSS
// NOTE: Only turn this on when needed - very heavy when resizing
// NOTE: Resize observer only from Safari 13.1 onwards, so back to window.onresize.
// new ResizeObserver(() => {
// ...
// }).observe(document.documentElement)
if (document.documentElement.classList.contains('is-ios') || document.documentElement.classList.contains('is-android')) {
  const onResizeHandler = () => {
    window.requestAnimationFrame(() => {
      document.documentElement.style.setProperty('--100vw', `${document.documentElement.clientWidth}px`)
      document.documentElement.style.setProperty('--100vh', `${document.documentElement.clientHeight}px`)
      document.documentElement.style.setProperty('--100dw', `${document.documentElement.offsetWidth}px`)
      document.documentElement.style.setProperty('--100dh', `${document.documentElement.offsetHeight}px`)
    })
  }
  window.addEventListener('resize', () => onResizeHandler())
  onResizeHandler()
}

// loading="lazy" polyfill (~3kB) - for non-Chrome
if (!('loading' in HTMLImageElement.prototype)) {
  require('loading-attribute-polyfill')
}

if (window.navigator.userAgent.indexOf('Safari') !== -1) {
  // Import smooth scroll (~35kB) - for Safari and old Edge
  if (!('scrollBehavior' in document.documentElement.style)) {
    const smoothscroll = require('smoothscroll-polyfill')
    smoothscroll.polyfill()
  }

  // Web animations polyfill ~(50kB) - for Safari and old Edge
  // About 50kb raw, so only use if you need it.
  // const x = import('web-animations-js')
}

// Show debug styles on L hotkey and grid on G hotkey when document is focussed.
// if (WEBPACK_ENV.ENV === 'development') {
if (window.location.href.indexOf('fabriquehq') !== -1 || window.location.href.indexOf('127.0.0.1') !== -1) {
  document.addEventListener('keydown', event => {
    if (event.target !== document.body) {
      return
    }

    if (event.key === 'l') {
      document.documentElement.classList.toggle('debug-layout')
    }

    document.cookie = `debug-layout=${document.documentElement.classList.contains('debug-layout')}; path=/;`
  })

  document.documentElement.classList.toggle('debug-layout', document.cookie.indexOf('debug-layout=true') !== -1)
}

async function getAppData () {
  const url = window.TBM_APP_CONFIG.app_data_fetch_url
  window.TBM_APP_DATA = url ? await apiCall(GET, url) : {}
}

getAppData().catch(console.log)
