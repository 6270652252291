import Component from '../../../assets/scripts/modules/component'

class StoryPanorama extends Component {
  init () {
    this.initSelectors()
    this.backButton.style.bottom = `${this.cardStory.offsetHeight}px`
    this.backButton.style.visibility = 'visible'
    this.element.addEventListener('click', () => { this.dragButton.classList.add('drag-button--hidden') })
    this.element.addEventListener('touchend', () => { this.dragButton.classList.add('drag-button--hidden') })
    this.initPanorama()
  }

  initSelectors () {
    this.panoramaContainer = this.element.querySelector('[data-role="story-panorama-container"]')
    this.panoramaImage = this.element.querySelector('[data-role="story-panorama-image"]')
    this.cardStory = this.element.querySelector('[data-role="story-video-card"]')
    this.backButton = this.element.querySelector('[data-role="back-button"]')
    this.dragButton = this.element.querySelector('[data-role="drag-button"]')
    this.locations = Array.from(document.querySelectorAll('[data-role="card-story-location"]'))
  }

  initPanorama () {
    const panorama = new PANOLENS.ImagePanorama(this.panoramaImage.src)

    this.locations.forEach((location) => {
      const x = +location.dataset.x
      const y = +location.dataset.y
      const z = +location.dataset.z
      const infospot = new PANOLENS.Infospot(0.5 * Math.pow(x * x + y * y + z * z, 1 / 3), PANOLENS.DataImage.Info)
      infospot.position.set(x, y, z)
      infospot.addHoverText(location.innerHTML)

      infospot.addEventListener('click', () => { infospot.focus() })
      location.addEventListener('click', (e) => {
        e.preventDefault()
        infospot.focus()
      })

      panorama.add(infospot)
    })

    const viewer = new PANOLENS.Viewer({ container: this.panoramaContainer })
    viewer.add(panorama)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.story-panorama').forEach(element => {
  element.instance = element.instance || new StoryPanorama(element)
}))
