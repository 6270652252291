
/**
 * Obtain width and height of an element in pixels.
 *
 * @param element
 * @returns {[number, number]}
 */
export function getDimensions (element) {
  const testElement = element.cloneNode(true)
  let extraStyle
  let height = 0
  let width = 0

  extraStyle = 'transition: none !important; visibility: hidden'
  extraStyle += 'position: absolute;'

  testElement.setAttribute('style', extraStyle)
  element.parentNode.appendChild(testElement)
  width = testElement.offsetWidth
  height = testElement.offsetHeight
  element.parentNode.removeChild(testElement)
  return [width, height]
}
