
import Component from '../../../assets/scripts/modules/component'

const STATE_DEFAULT = 'default'
const STATE_FILTER_PANEL_OPEN = 'filter-panel-open'
const STATE_DATE_PANEL_OPEN = 'date-panel-open'

function setTabIndexes (region, isCollapsed) {
  const focusableElements = [...region.querySelectorAll('a[href], button')]
  focusableElements.forEach(element => isCollapsed ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex'))
}

function updateRegion (region, toggleButtons, isVisible) {
  if (!region) {
    return
  }

  region.setAttribute('aria-hidden', isVisible ? 'false' : 'true')

  toggleButtons.forEach((button) => {
    button.setAttribute('aria-expanded', isVisible ? 'true' : 'false')
  })

  setTabIndexes(region, !isVisible)
}

function keepScrollPosition () {
  const scrollPosition = localStorage.getItem('TBM_SCROLL_POSITION')

  if (scrollPosition) {
    localStorage.removeItem('TBM_SCROLL_POSITION')
    window.scrollTo({ top: parseInt(scrollPosition, 10), behavior: 'instant' })
  }
}

class FilterBarComponent extends Component {
  init () {
    this.state = STATE_DEFAULT
    this.mainPanel = this.element.querySelector('[data-role=main-panel]')
    this.filterPanel = this.element.querySelector('[data-role=filter-panel]')
    this.datePanel = this.element.querySelector('[data-role=date-panel]')
    this.filterPanelToggles = [...this.element.querySelectorAll('[data-role=filter-panel-toggle]')]
    this.datePanelToggles = [...this.element.querySelectorAll('[data-role=date-panel-toggle]')]

    this.setupFilterPanelToggles()
    this.setupDatePanelToggles()
    this.keepScrollPosition()
    this.update()
  }

  keepScrollPosition () {
    keepScrollPosition()
    this.element.querySelectorAll('a').forEach(link => {
      if (link.getAttribute('href').startsWith('#') || new URL(link.getAttribute('href')).pathname !== window.location.pathname) {
        return
      }

      link.addEventListener('click', () => {
        localStorage.setItem('TBM_SCROLL_POSITION', '' + window.scrollY)
      })
    })
  }

  setupFilterPanelToggles () {
    this.filterPanelToggles.forEach(e => {
      e.addEventListener('click', () => {
        this.state = this.state === STATE_FILTER_PANEL_OPEN ? STATE_DEFAULT : STATE_FILTER_PANEL_OPEN
        this.update()
      })
    })
  }

  setupDatePanelToggles () {
    this.datePanelToggles.forEach(e => {
      e.addEventListener('click', () => {
        this.state = this.state === STATE_DATE_PANEL_OPEN ? STATE_DEFAULT : STATE_DATE_PANEL_OPEN
        this.update()

        this.datePanelToggles.forEach(toggle => {
          toggle.classList.toggle('button-toggle--hidden')
          toggle.setAttribute('aria-hidden', toggle.classList.contains('button-toggle--hidden'))
        })
      })
    })
  }

  update () {
    this.element.setAttribute('data-state', this.state)
    updateRegion(this.mainPanel, [], this.state !== STATE_FILTER_PANEL_OPEN)
    updateRegion(
      this.filterPanel,
      this.filterPanelToggles,
      this.state === STATE_FILTER_PANEL_OPEN
    )
    updateRegion(
      this.datePanel,
      this.datePanelToggles,
      this.state === STATE_DATE_PANEL_OPEN
    )
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.filter-bar').forEach(element => {
  element.instance = element.instance || new FilterBarComponent(element)
}))
