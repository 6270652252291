
import Component from '../../../assets/scripts/modules/component'

export default class NotificationBarComponent extends Component {
  init () {
    const shown = sessionStorage.getItem('notification-bar-dismissed')

    this.menuBar = document.querySelector('.menu-bar')

    if (shown) {
      return
    }

    this.menuBar.style.marginTop = `${this.element.offsetHeight - 25}px`
    this.element.classList.add('notification-bar--visible')

    this.element.querySelector('.notification-bar--close').addEventListener('click', () => {
      this.menuBar.style.marginTop = 0
      this.element.classList.remove('notification-bar--visible')
      sessionStorage.setItem('notification-bar-dismissed', true)
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.notification-bar').forEach(element => {
  element.instance = element.instance || new NotificationBarComponent(element)
}))
