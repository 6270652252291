
import Component from '../../../assets/scripts/modules/component'

class CardGridHomeComponent extends Component {
  init () {
    this.initSwitchView()
    // this.scrollMove()
  }

  // Temporary simple scroll down script for now
  initSwitchView () {
    if (window.innerWidth > 800) {
      document.body.style.overflowY = 'auto'
      window.scrollTo(0, 0)
    }
    this.seeMoreButton = this.element.querySelector('.card-grid-home__button')
    this.topRegion = this.element.querySelector('.card-grid-home__grid')
    console.log(this.seeMoreButton)
    if (!this.seeMoreButton || !this.topRegion) {
      return
    }

    this.seeMoreButton.addEventListener('click', () => this.toggleRegion(this.topRegion, this.seeMoreButton))
    this.setTabIndexes(this.topRegion, true)
  }

  scrollMove () {
    if (window.innerWidth > 800) {
      const headerBlock = this.element.querySelector('.card-grid-home__introduction')
      // const headerBlock = document.getElementById('hero-header__wrapper')
      window.addEventListener('scroll', function () {
        const st = window.pageYOffset || document.documentElement.scrollTop
        const scrollPercentage = Math.round((st * (100 / window.innerHeight)))
        if (scrollPercentage < 100) {
          headerBlock.style.width = (100 - (scrollPercentage / 2)) + 'vw'
          // headerBlock.style.transform = 'translateX(-' + (scrollPercentage / 2) + '%)'
        }
      }, false)
    }
  }

  toggleRegion (region, button) {
    if (!region || !button) {
      return
    }
    const height = region.offsetHeight
    window.scrollTo(0, height * 2)
    const isCollapsing = (button.getAttribute('aria-expanded') === 'true')

    region.setAttribute('aria-hidden', isCollapsing)
    button.setAttribute('aria-expanded', !isCollapsing)

    this.setTabIndexes(region, isCollapsing)
    if (window.innerWidth > 800) {
      document.body.style.overflowY = 'auto'
    }
  }

  setTabIndexes (region, isCollapsed) {
    const focusableElements = [...region.querySelectorAll('a[href], button')]
    focusableElements.forEach(element => isCollapsed ? element.setAttribute('tabindex', -1) : element.removeAttribute('tabindex'))
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.card-grid-home').forEach(element => {
  element.instance = element.instance || new CardGridHomeComponent(element)
}))
