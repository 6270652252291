
import './../../../assets/scripts/modules/daterangepicker'

import Component from '../../../assets/scripts/modules/component'
import $ from './../../../assets/scripts/modules/jquery.module.js'

const ONE_DAY = 24 * 60 * 60 * 1000

function formatDate (date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

function cmpDate (d, e) {
  return d.getFullYear() === e.getFullYear() && d.getMonth() === e.getMonth() && d.getDate() === e.getDate()
}

function parseDate (date) {
  const [year, month, day] = date.split('-').map(Number)
  return new Date(year, month - 1, day)
}

function parseOptionalDate (date) {
  return date ? parseDate(date) : null
}

function initializeDaterangepicker (parent, element, config) {
  const $element = $(element)
  const { ranges } = config

  const fromDate = parseOptionalDate(element.dataset.fromDate)
  const toDate = parseOptionalDate(element.dataset.toDate)

  const datepickerConfig = {
    alwaysShowCalendars: true,
    autoApply: true,
    isCustomDate: (momentDate) => {
      const d = momentDate.toDate()

      if (fromDate && !toDate && d > fromDate) {
        return ['in-range']
      }

      if (!fromDate && toDate && d < toDate) {
        return ['in-range']
      }

      if (!fromDate && cmpDate(d, toDate)) {
        return ['no-start']
      }

      if (!toDate && cmpDate(d, fromDate)) {
        return ['no-end']
      }
    },
    linkedCalendars: true,
    locale: {
      format: 'YYYY-MM-DD',
      cancelLabel: 'Close calendar'
    },
    parentEl: parent,
    ranges,
    showDropdowns: true
  }

  const validFromDate = fromDate || formatDate(new Date())

  datepickerConfig.startDate = validFromDate
  datepickerConfig.endDate = toDate || validFromDate

  return $element.daterangepicker(datepickerConfig)
}

class DatepickerComponent extends Component {
  init () {
    const input = this.element.querySelector('input')
    const id = input.id
    const fromDateParameterName = input.dataset.fromDateName
    const toDateParameterName = input.dataset.toDateName
    const config = JSON.parse(document.getElementById(`${id}-config`).textContent)
    const openCalendarBtn = this.element.querySelector('.button--calendar')

    this.daterangepicker = initializeDaterangepicker(
      this.element.querySelector('[data-role=datepicker-container]'),
      input,
      config
    )

    const setDisplayProperty = () => {
      const dataRangePicker = document.querySelector('.daterangepicker')
      if (dataRangePicker) dataRangePicker.style.display = window.innerWidth >= 680 ? 'grid' : 'inline-block'
    }

    window.addEventListener('resize', () => { setDisplayProperty() })

    this.daterangepicker.on('showCalendar.daterangepicker', function (event, picker) {
      console.log('showing calendar')
      this.calendars = document.querySelectorAll('.drp-calendar')
      this.closeCalendarBtn = document.querySelector('.cancelBtn')
      openCalendarBtn.classList.add('button--calendar--hidden')
      if (this.closeCalendarBtn) this.closeCalendarBtn.classList.add('cancelBtn--visible')
      this.calendars.forEach((el) => {
        el.classList.remove('drp-calendar--hidden')
        el.classList.add('margin-calendar')
      })
    })

    this.daterangepicker.on('hide.daterangepicker', function (event, picker) {
      openCalendarBtn.classList.remove('button--calendar--hidden')
      if (this.closeCalendarBtn) this.closeCalendarBtn.classList.remove('cancelBtn--visible')
      if (this.calendars) this.calendars.forEach((el) => el.classList.add('drp-calendar--hidden'))
      setDisplayProperty()
    })

    this.daterangepicker.on('apply.daterangepicker', (_, drp) => {
      const fromDate = drp.startDate.toDate()
      const toDate = drp.endDate.toDate()
      const fmtFromDate = formatDate(fromDate)
      const fmtToDate = formatDate(toDate)
      const urlParams = new URLSearchParams(window.location.search)

      if (fromDate > toDate) {
        const code = Math.floor((fromDate - toDate) / ONE_DAY)
        if (code === 0) {
          // Encoded value for past events
          urlParams.delete(fromDateParameterName)
          urlParams.set(toDateParameterName, fmtToDate)
        } else if (code === 1) {
          // Encoded value for future events
          urlParams.set(fromDateParameterName, fmtFromDate)
          urlParams.delete(toDateParameterName)
        } else {
          // We don't know :s
          throw new Error('Unknown state')
        }
      } else {
        urlParams.set(fromDateParameterName, fmtFromDate)
        urlParams.set(toDateParameterName, fmtToDate)
      }

      window.location.href = `?${urlParams}`
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.datepicker').forEach(element => {
  element.instance = element.instance || new DatepickerComponent(element)
}))
