const STATUS_OK = 'ok'
const STATUS_ERROR = 'error'

class Response {
  constructor (status, message = '', redirectURL = '') {
    this.status = status
    this.message = message
    this.redirectURL = redirectURL
  }
}

class NewsletterBackend {
  async subscribe (emailAddress) {
    throw new Error('Not implemented!')
  }
}

export { NewsletterBackend, Response, STATUS_ERROR, STATUS_OK }
