import { throttle } from 'lodash'

import { getDimensions } from '../utilities/get-dimensions'

export default class HScrollContainer {
  constructor (containerElement, options) {
    this.options = {
      calcAdditionalWidth: () => 0,
      ...options
    }
    this.containerElement = containerElement
    this.scrollableElement = containerElement.firstElementChild
    this.fixedElements = [...containerElement.children].slice(1)
    this.scrollDummy = document.createElement('div')
    containerElement.appendChild(this.scrollDummy)
    this.recalculateDimensions()
    const resizeThrottle = throttle(() =>
      window.requestAnimationFrame(() => this.onResize()), 30, { leading: true, trailing: true }
    )
    const scrollThrottle = throttle(() =>
      window.requestAnimationFrame(() => this.onScroll()), 30, { leading: true, trailing: true }
    )
    window.addEventListener('resize', resizeThrottle)
    window.addEventListener('scroll', scrollThrottle)
    this.setup()
  }

  setup () {
    this.containerElement.style.position = 'relative'
  }

  getProgress () {
    const transformHeight = this.scrollHeight - this.height
    const progress = (window.scrollY - this.hScrollStart) / transformHeight
    return Math.max(0, Math.min(1, progress))
  }

  onResize () {
    this.recalculateDimensions()
  }

  onScroll () {
    const transformHeight = this.scrollHeight - this.height
    const progress = this.getProgress()
    const offset = this.containerElement.scrollLeft
    let transformY

    if (offset) {
      this.containerElement.scroll({ left: 0, behavior: 'instant' })
      window.scroll({ top: offset, behavior: 'instant' })
      return
    }

    if (progress >= 0 && progress < 1) {
      this.fixedElements.concat([this.scrollableElement]).forEach(element => {
        element.style.position = 'fixed'
      })
      transformY = 0
    } else {
      this.fixedElements.concat([this.scrollableElement]).forEach(element => {
        element.style.position = 'absolute'
      })
      transformY = progress < 0.5 ? 0 : transformHeight
    }

    this.scrollableElement.style.transform = `translate(-${progress * transformHeight}px, ${transformY}px)`
    this.fixedElements.forEach(element => {
      element.style.transform = `translate(0, ${transformY}px)`
    })
  }

  recalculateDimensions () {
    const minScrollHeight = window.innerHeight
    const [width, height] = getDimensions(this.scrollableElement)
    const scrollHeight = Math.max(
      minScrollHeight,
      width + height - window.innerWidth + this.options.calcAdditionalWidth()
    )
    const bounds = this.containerElement.getBoundingClientRect()
    this.width = width
    this.height = height
    this.scrollHeight = scrollHeight
    this.scrollDummy.style.width = `${width}px`
    this.hScrollStart = Math.max(0, window.scrollY + bounds.top - (window.innerHeight - height) / 2)
    this.hScrollEnd = this.hScrollStart + scrollHeight
    this.containerElement.style.height = `${scrollHeight}px`
    this.onScroll()

    clearTimeout(this.recalculateTimeout)
  }
}
