import Component from '../../../assets/scripts/modules/component'

class StoryVideoEmbed extends Component {
  init () {
    this.iframe = null
    this.initSelectors()
    this.initEvents()

    this.backButton.style.bottom = `${this.cardStory.offsetHeight}px`
  }

  initSelectors () {
    this.cardStory = this.element.querySelector('[data-role="story-video-card"]')
    this.backButton = this.element.querySelector('[data-role="back-button"]')
    this.closeButton = this.element.querySelector('[data-role="close-button"]')
    this.playButton = this.element.querySelector('[data-role="play-button"]')
    this.picture = this.element.querySelector('picture')
    this.hitTarget = this.element.querySelector('[data-role="hit-target"]')
  }

  initEvents () {
    this.playButton.addEventListener('click', () => { this.hitTarget.click() })

    const hideMenuBarEvent = new CustomEvent('hide-menu-bar', { bubbles: true })
    const showMenuBarEvent = new CustomEvent('show-menu-bar', { bubbles: true })
    const stopPlayerEvent = new CustomEvent('stop-player', { bubbles: true })

    this.element.addEventListener('youtube-loaded', () => {
      this.iframe = this.element.querySelector('.video-embed__iframe')
    })

    this.element.addEventListener('video-played', () => {
      this.element.dispatchEvent(hideMenuBarEvent)
      this.displayOnlyVideo()
    })

    this.closeButton.addEventListener('click', () => {
      this.element.dispatchEvent(showMenuBarEvent)
      this.element.dispatchEvent(stopPlayerEvent)
      this.hideVideo()
    })

    window.addEventListener('resize', (event) => {
      this.backButton.style.bottom = `${this.cardStory.offsetHeight}px`
    })
  }

  displayOnlyVideo () {
    this.cardStory.classList.add('card-story--hidden')
    this.backButton.classList.add('back-button--hidden')
    this.closeButton.classList.add('close-button--visible')
    if (this.iframe) {
      this.iframe.classList.remove('iframe--hidden')
    }
  }

  hideVideo () {
    this.cardStory.classList.remove('card-story--hidden')
    this.backButton.classList.remove('back-button--hidden')
    this.closeButton.classList.remove('close-button--visible')
    this.iframe.classList.add('iframe--hidden')
    this.picture.style.opacity = 1
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.story-video-embed').forEach(element => {
  element.instance = element.instance || new StoryVideoEmbed(element)
}))
