import { NewsletterBackend, Response, STATUS_ERROR, STATUS_OK } from './base'

class DummyNewsletterBackend extends NewsletterBackend {
  async subscribe (emailAddress) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(Math.random() > 0.5 ? new Response(STATUS_OK) : new Response(STATUS_ERROR, 'Something went wrong'))
      }, 1000)
    })
  }
}

export { DummyNewsletterBackend }
