function initDraggable (elementToDrag, elementDragIndicator, isDraggingAllowed) {
  let isDown = false
  let startX
  let scrollLeft

  const mouseDown = (event) => {
    if (isDraggingAllowed()) {
      if (elementDragIndicator) {
        elementDragIndicator.classList.remove('drag-indicator--visible')
      }

      isDown = true
      startX = event.pageX - elementToDrag.offsetLeft
      scrollLeft = elementToDrag.scrollLeft
    }
  }

  const mouseMove = (event) => {
    if (!isDown) return
    event.preventDefault()
    const x = event.pageX - elementToDrag.offsetLeft
    const walk = (x - startX) * 3 // scroll-fast
    elementToDrag.scrollLeft = scrollLeft - walk
  }

  const mouseUp = () => {
    isDown = false
  }

  const touchStart = () => {
    if (isDraggingAllowed()) {
      this.dragIndicator.classList.remove('drag-indicator--visible')
    }
  }

  elementToDrag.addEventListener('touchstart', touchStart)
  elementToDrag.addEventListener('mousedown', mouseDown)
  elementToDrag.addEventListener('mouseup', mouseUp)
  elementToDrag.addEventListener('mouseleave', mouseUp)
  elementToDrag.addEventListener('mousemove', mouseMove)
}

export { initDraggable }
