import Component from '../../../assets/scripts/modules/component'
import HScrollContainer from '../../../assets/scripts/modules/h-scroll-container'

// const CURSOR_SIZE = 200
// const MIN_WIDTH_CONTAINERS = 40
// const MIN_HEIGHT_PORCENTAGE_DRAG = 0.5
// const MIN_PIXELS = 530
const HEIGHT_MENU_BAR = 77
// const STATE_INITIAL = 'initial'
// const STATE_SCROLLED = 'scrolled'
// const BUTTON_HEIGHT = 48

class HeaderLayer extends Component {
  init () {
    this.initSelectors()
    this.initBehavior()
  }

  initSelectors () {
    this.introContainer = document.querySelector('.homepage-header-intro')
    this.scrollableContainer = document.querySelector('[data-role="scrollable-container"]')
    // this.headerWrapper = this.element.querySelector('[data-role="homepage-header--wrapper"]')
    // this.headerContent = this.element.querySelector('[data-role="homepage-header--content"]')
    // this.secondContent = this.element.querySelector('[data-role="homepage-header--second-content"]')
    // this.cursor = this.element.querySelector('[data-role="homepage-header--cursor"]')
    // this.cursorAltContent = this.element.querySelector('[data-role="homepage-header--alt-content"]')
    // this.homepageButton = document.querySelector('[data-role="homepage-header__button"]')
    this.seeMoreButton = document.querySelector('[data-role="see-more-button"]')
    this.exploreButton = document.querySelector('[data-role="explore-button"]')
    // this.cardsContainer = document.querySelector('[data-role="homepage-header--cards"]')
    // this.dragIndicator = this.element.querySelector('[data-role="homepage-header--drag-indicator"]')

    // if (this.secondContent) {
    //   this.headerWrapper.classList.add('cursor-pointer')
    // }
  }

  initBehavior () {
    this.hScrollContainer = new HScrollContainer(this.scrollableContainer)

    window.addEventListener('scroll', () => this.onScroll())

    this.seeMoreButton.addEventListener('click', () => this.scrollToBottom())
    this.exploreButton.addEventListener('click', () => this.scrollToTop())
  }

  onScroll () {
    const progress = this.hScrollContainer.getProgress()

    if (progress >= 1) {
      this.element.classList.add('homepage-header--passed')
    } else {
      this.element.classList.remove('homepage-header--passed')
    }

    if (window.scrollY > this.hScrollContainer.hScrollEnd - HEIGHT_MENU_BAR) {
      this.element.classList.add('homepage-header--sticky-explore')
    } else {
      this.element.classList.remove('homepage-header--sticky-explore')
    }
  }

  scrollToTop () {
    const scrollDestination = Math.min(
      this.hScrollContainer.scrollHeight - window.innerHeight,
      window.innerWidth * 0.6
    )
    window.scroll({
      top: scrollDestination,
      left: 0,
      behavior: 'smooth'
    })

    this.onScroll()
  }

  scrollToBottom () {
    const scrollDestination = Math.min(
      this.hScrollContainer.scrollHeight - window.innerHeight,
      window.innerWidth * 0.6
    )
    if (window.scrollY < scrollDestination) {
      window.scroll({
        top: scrollDestination,
        left: 0,
        behavior: 'smooth'
      })
    } else {
      window.scroll({
        top: this.element.getBoundingClientRect().height,
        left: 0,
        behavior: 'smooth'
      })
    }

    this.onScroll()
  }

  // initEvents () {
  //   this.mouseX = 0
  //   this.mouseY = 0
  //   this.active = false
  //   this.isTouchDevice = false
  //   this.touchCounter = null
  //   this.state = STATE_INITIAL
  //
  //   document.addEventListener('scroll', () => this.onScroll())
  //
  //   const onClickHeaderWrapper = (event) => {
  //     this.headerContent.classList.toggle('homepage-header__content--hidden')
  //     this.secondContent.classList.toggle('second-content--active')
  //
  //     if (!this.isTouchDevice && window.innerWidth > MIN_PIXELS && this.state === STATE_INITIAL) {
  //       if (this.secondContent.classList.contains('second-content--active')) {
  //         this.cursor.classList.remove('homepage-header__cursor--visible')
  //       } else {
  //         this.cursor.classList.add('homepage-header__cursor--visible')
  //       }
  //     }
  //   }
  //
  //   if (this.secondContent) {
  //     this.headerWrapper.addEventListener('click', onClickHeaderWrapper)
  //
  //     this.headerWrapper.addEventListener('touchstart', (event) => {
  //       this.cursor.classList.remove('homepage-header__cursor--visible')
  //       this.isTouchDevice = true
  //     })
  //
  //     this.headerWrapper.addEventListener('mousemove', (event) => {
  //       if (this.state === STATE_INITIAL) {
  //         this.mouseX = event.clientX
  //         this.mouseY = event.clientY
  //         this.update()
  //       }
  //     })
  //
  //     this.headerWrapper.addEventListener('mouseleave', () => {
  //       this.cursor.classList.remove('homepage-header__cursor--visible')
  //     })
  //
  //     this.headerWrapper.addEventListener('mouseenter', () => {
  //       if (!this.secondContent.classList.contains('second-content--active') && !this.isTouchDevice && window.innerWidth > MIN_PIXELS && this.state === STATE_INITIAL) {
  //         this.cursor.classList.add('homepage-header__cursor--visible')
  //       }
  //     })
  //
  //     // Wait for first mousemove to enable cursor layer
  //     const setupListener = (event) => {
  //       if (!this.isTouchDevice && !this.active) {
  //         this.active = true
  //         this.cursor.classList.add('homepage-header__cursor--visible')
  //       }
  //     }
  //     this.headerWrapper.addEventListener('mousemove', setupListener)
  //   }
  //
  //   const scrollToBottom = () => {
  //     let top
  //     if (window.innerWidth > MIN_PIXELS) {
  //       if (window.pageYOffset < window.innerHeight) {
  //         top = window.innerHeight
  //       } else {
  //         this.setNewState()
  //         top = (window.innerHeight * 2) - HEIGHT_MENU_BAR
  //       }
  //     } else {
  //       if (window.pageYOffset % window.innerHeight === 0) {
  //         top = window.innerHeight + window.pageYOffset
  //       } else {
  //         top = (Math.trunc(window.pageYOffset / window.innerHeight) + 1) * window.innerHeight
  //       }
  //     }
  //
  //     window.scroll({
  //       top: top,
  //       left: 0,
  //       behavior: 'smooth'
  //     })
  //   }
  //
  //   const scrollToTop = () => {
  //     this.setInitialState()
  //
  //     window.scroll({
  //       top: 0,
  //       left: 0,
  //       behavior: 'smooth'
  //     })
  //   }
  //
  //   this.seeMoreButton.addEventListener('click', scrollToBottom)
  //   this.exploreButton.addEventListener('click', scrollToTop)
  //
  //   const resize = () => {
  //     if (window.innerWidth > MIN_PIXELS) {
  //       this.cardsContainer.style.transform = `translateX(${this.headerWrapper.offsetWidth}px)`
  //     } else {
  //       this.cardsContainer.style.transform = 'translateX(0)'
  //       this.headerWrapper.style.width = '100%'
  //
  //       if (this.secondContent) {
  //         this.cursor.classList.remove('homepage-header__cursor--visible')
  //         this.cursorAltContent.style.width = '100%'
  //       }
  //     }
  //   }
  //   window.addEventListener('resize', resize)
  // }

  // onScroll () {
  //   if (window.innerWidth > MIN_PIXELS) {
  //     const heightHeaderWrapper = this.headerScrollable.offsetHeight
  //     const scrollY = window.scrollY
  //     const porcentageHeaderWrapperScrolled = (scrollY / heightHeaderWrapper) * 100
  //     const width = 100 - porcentageHeaderWrapperScrolled
  //
  //     if (scrollY < heightHeaderWrapper) {
  //       this.setWidthContainers(width)
  //       if (this.headerScrollable.scrollLeft > 0) {
  //         this.headerScrollable.classList.add('scroll-smooth')
  //         this.headerScrollable.scrollLeft = 0
  //       }
  //     }
  //
  //     this.headerScrollable.classList.remove('scroll-smooth')
  //     this.toggleDraggableClasses()
  //     this.updateStates()
  //   } else {
  //     const headerRect = this.element.getBoundingClientRect()
  //
  //     if (headerRect.bottom < window.innerHeight) {
  //       this.seeMoreButton.classList.add('button-see-more--hidden')
  //     } else {
  //       this.seeMoreButton.classList.remove('button-see-more--hidden')
  //     }
  //   }
  // }

  // updateStates () {
  //   const headerRect = this.element.getBoundingClientRect()
  //
  //   if (headerRect.bottom - BUTTON_HEIGHT < window.innerHeight) {
  //     if (this.state !== STATE_SCROLLED) {
  //       this.state = STATE_SCROLLED
  //       this.setNewState()
  //     }
  //   } else {
  //     if (this.state !== STATE_INITIAL) {
  //       this.state = STATE_INITIAL
  //       this.setInitialState()
  //     }
  //   }
  // }
  //
  // setNewState () {
  //   this.headerScrollable.classList.add('header-scrollable--absolute')
  //   this.homepageButton.classList.add('button-initial-position')
  //   this.seeMoreButton.classList.add('button-see-more--hidden')
  //   this.exploreButton.classList.add('button-explore--visible')
  //   this.active = false
  //
  //   if (this.secondContent) {
  //     this.cursor.classList.remove('homepage-header__cursor--visible')
  //   }
  // }
  //
  // setInitialState () {
  //   this.headerScrollable.classList.remove('header-scrollable--absolute')
  //   this.seeMoreButton.classList.remove('button-see-more--hidden')
  //   this.exploreButton.classList.remove('button-explore--visible')
  //   this.homepageButton.classList.remove('button-initial-position')
  // }
  //
  // setWidthContainers (width) {
  //   if (width >= MIN_WIDTH_CONTAINERS) {
  //     if (this.secondContent) {
  //       this.cursorAltContent.style.width = `${width}%`
  //     }
  //
  //     this.headerWrapper.style.width = `${width}%`
  //   } else {
  //     if (this.secondContent) {
  //       this.cursorAltContent.style.width = `${MIN_WIDTH_CONTAINERS}%`
  //     }
  //     this.headerWrapper.style.width = `${MIN_WIDTH_CONTAINERS}%`
  //   }
  //   this.cardsContainer.style.transform = `translateX(${this.headerWrapper.offsetWidth}px)`
  // }
  //
  // toggleDraggableClasses () {
  //   // define when the drag option is available -> now: 50%
  //   if (window.scrollY >= (window.innerHeight * MIN_HEIGHT_PORCENTAGE_DRAG)) {
  //     this.headerScrollable.classList.add('draggable')
  //     this.dragIndicator.classList.add('drag-indicator--visible')
  //   } else {
  //     this.headerScrollable.classList.remove('draggable')
  //     this.dragIndicator.classList.remove('drag-indicator--visible')
  //   }
  // }
  //
  // update () {
  //   const xOffset = this.mouseX
  //   const yOffset = this.mouseY
  //   this.cursor.style.transform = `translate(${xOffset}px, ${yOffset}px)`
  //   this.cursorAltContent.style.transform = `translate(${-xOffset + CURSOR_SIZE / 2}px, ${-yOffset + CURSOR_SIZE / 2}px)`
  // }
}

window.addEventListener('init-load', () => document.querySelectorAll('.homepage-header').forEach(element => {
  element.instance = element.instance || new HeaderLayer(element)
}))
