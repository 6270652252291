import Component from '../../../assets/scripts/modules/component'
import { STATUS_ERROR as BACKEND_STATUS_ERROR, STATUS_OK as BACKEND_STATUS_OK } from './newsletter/base'
import { DummyNewsletterBackend } from './newsletter/dummy'
import { RealNewsletterBackend } from './newsletter/real'

const STATUS_START = 'start'
const STATUS_LOADING = 'loading'
const STATUS_SUCCESS = 'success'
const STATUS_ERROR = 'error'

class FooterComponent extends Component {
  init () {
    const backendConfig = window.TBM_APP_CONFIG.newsletter_backend || { name: 'dummy' }
    this.element.addEventListener('submit', this.preventMultipleSubmits.bind(this))
    this.status = STATUS_START
    this.newsletterBackend = new (new Map([
      ['dummy', DummyNewsletterBackend],
      ['real', RealNewsletterBackend]
    ]).get(backendConfig.name))(backendConfig)

    this.initializeContainers()
    this.checkStatus()
  }

  initializeContainers () {
    this.formContainer = this.element.querySelector('[data-status="start"]')
    this.loadingContainer = this.element.querySelector('[data-status="loading"]')
    this.successContainer = this.element.querySelector('[data-status="success"]')
    this.errorContainer = this.element.querySelector('[data-status="error"]')
    this.errorMessageContainer = this.element.querySelector('[data-role="error-message"]')
    this.statusContainers = Array.from(this.element.querySelectorAll('[data-status]'))
  }

  checkStatus () {
    this.statusContainers.forEach(container => {
      container.classList.add('subscription-message-container--hidden')
    })

    if (this.status === STATUS_START) {
      this.displayElement(this.formContainer)
    } else if (this.status === STATUS_LOADING) {
      this.displayElement(this.loadingContainer)
    } else if (this.status === STATUS_SUCCESS) {
      this.displayElement(this.successContainer)
    } else if (this.status === STATUS_ERROR) {
      this.displayElement(this.errorContainer)
    }
  }

  displayElement (element) {
    element.classList.remove('subscription-message-container--hidden')
  }

  preventMultipleSubmits (event) {
    event.preventDefault()
    this.submitForm()
  }

  async submitForm () {
    this.status = STATUS_LOADING
    this.checkStatus()
    const email = this.formContainer.email.value
    const response = await this.newsletterBackend.subscribe(email)
    this.status = new Map([
      [BACKEND_STATUS_OK, STATUS_SUCCESS],
      [BACKEND_STATUS_ERROR, STATUS_ERROR]
    ]).get(response.status) || STATUS_ERROR
    this.errorMessageContainer.innerText = response.message
    const top = screen.height / 2
    const left = screen.width / 2
    const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=800,left=${left},top=${top}`
    window.open(response.redirectURL, 'newsletter', params)
    this.checkStatus()
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.footer-bar__form-wrapper').forEach(element => {
  element.instance = element.instance || new FooterComponent(element)
}))
