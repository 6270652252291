
import Component from '../../../assets/scripts/modules/component'

class EventHeaderComponent extends Component {
  init () {
    this.scrollMove()
  }

  scrollMove () {
    const scrollButton = this.element.querySelector('.event-header__scroll-button')

    window.addEventListener('scroll', function () {
      if (window.pageYOffset > 30) {
        scrollButton.style.opacity = '0'
      } else {
        scrollButton.style.opacity = '1'
      }
    }, false)
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.event-header').forEach(element => {
  element.instance = element.instance || new EventHeaderComponent(element)
}))
