
import savvior from 'savvior'

import Component from '../../../assets/scripts/modules/component'
import { hydrate } from '../../../assets/scripts/utilities/hydrator'

class CardMosaicComponent extends Component {
  init () {
    const itemContainer = this.element.querySelector('.card-mosaic__wrapper')
    itemContainer.id = `s${Math.random().toString().split('.')[1]}`
    savvior.init(`#${itemContainer.id}`, {
      'screen and (max-width: 600px)': { columns: 2 },
      'screen and (min-width: 601px) and (max-width: 1024px)': { columns: 3 },
      'screen and (min-width: 1025px)': { columns: 4 }
    })
    window.addEventListener('savvior:setup', () => {
      hydrate(itemContainer)
    })
  }
}

window.addEventListener('init-load', () => document.querySelectorAll('.card-mosaic').forEach(element => {
  element.instance = element.instance || new CardMosaicComponent(element)
}))
